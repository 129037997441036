import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
// import Matches from '../views/Matches.vue'
import Match from './views/Match.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Home
  },
  {
    path: '/register',
    name: 'Register',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('./views/About.vue')
  },
  {
    path: '/matches',
    name: 'Matches',
    component: () => import('./views/Matches.vue')
  },
  {
    path: '/match',
    name: 'Match',
    component: Match
  },
  { path: '*', redirect: '/' },
]

const router = new VueRouter({
  routes
})

export default router
