<template>
  <div class="match" :class="swap ? 'swap' : ''">
    <div class="gridwrapper">
      <div class="distribution left">
        <div
          class="e"
          :style="
            'height: ' +
            (currentDistribution1[0] /
              (currentDistribution1[0] +
                currentDistribution1[1] +
                currentDistribution1[2])) *
              100 +
            '%;'
          "
        ></div>
        <div
          class="w"
          :style="
            'height: ' +
            (currentDistribution1[1] /
              (currentDistribution1[0] +
                currentDistribution1[1] +
                currentDistribution1[2])) *
              100 +
            '%;'
          "
        ></div>
        <div
          class="f"
          :style="
            'height: ' +
            (currentDistribution1[2] /
              (currentDistribution1[0] +
                currentDistribution1[1] +
                currentDistribution1[2])) *
              100 +
            '%;'
          "
        ></div>
      </div>
      <div class="distribution right">
        <div
          class="e"
          :style="
            'height: ' +
            (currentDistribution2[0] /
              (currentDistribution2[0] +
                currentDistribution2[1] +
                currentDistribution2[2])) *
              100 +
            '%;'
          "
        ></div>
        <div
          class="w"
          :style="
            'height: ' +
            (currentDistribution2[1] /
              (currentDistribution2[0] +
                currentDistribution2[1] +
                currentDistribution2[2])) *
              100 +
            '%;'
          "
        ></div>
        <div
          class="f"
          :style="
            'height: ' +
            (currentDistribution2[2] /
              (currentDistribution2[0] +
                currentDistribution2[1] +
                currentDistribution2[2])) *
              100 +
            '%;'
          "
        ></div>
      </div>

      <div
        class="grid"
        :style="'grid-template-rows: repeat(' + board.height + ', 1fr);'"
      >
        <div
          v-for="y in board.height"
          :key="y"
          :style="'grid-template-columns: repeat(' + board.width + ', 1fr); '"
        >
          <div v-for="x in board.width" :key="x">
            <div
              class="tile"
              :class="[tileClass(x, y), selectableTile(x, y)]"
              :key="x"
              :ref="'tile_' + x + '_' + y"
              @click="selectTile(x, y)"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="playerbar">
      <div class="content">
        <div class="score">
          <div class="score1">
            {{ p1score[0] }}
          </div>
          <div class="score2">
            {{ p1score[1] }}
          </div>
        </div>
        <div class="playerturn" :class="'player' + whosMove">
          <strong
            >{{ whosMove == 1 ? player1name : player2name }}'s' turn</strong
          >
        </div>
        <label class="radiowrapper"
          >EARTH
          <input type="radio" v-model="newMove.Type" value="EARTH" />
          <span class="checkmark"></span>
        </label>

        <label class="radiowrapper"
          >FIRE
          <input type="radio" v-model="newMove.Type" value="FIRE" />
          <span class="checkmark"></span>
        </label>

        <label class="radiowrapper"
          >WATER
          <input type="radio" v-model="newMove.Type" value="WATER" />
          <span class="checkmark"></span>
        </label>

        <div @click="swap = !swap">swap</div>
      </div>
    </div>

    <div class="playerbar swap">
      <div class="content">
        <div class="score">
          <div class="score1">
            {{ p1score[1] }}
          </div>
          <div class="score2">
            {{ p1score[0] }}
          </div>
        </div>
        <div class="playerturn" :class="'player' + whosMove">
          <strong
            >{{ whosMove == 1 ? player1name : player2name }}'s' turn</strong
          >
        </div>
        <label class="radiowrapper"
          >EARTH
          <input type="radio" v-model="newMove.Type" value="EARTH" />
          <span class="checkmark"></span>
        </label>

        <label class="radiowrapper"
          >FIRE
          <input type="radio" v-model="newMove.Type" value="FIRE" />
          <span class="checkmark"></span>
        </label>

        <label class="radiowrapper"
          >WATER
          <input type="radio" v-model="newMove.Type" value="WATER" />
          <span class="checkmark"></span>
        </label>

        <div @click="swap = !swap">swap</div>
      </div>
    </div>

    <!-- <div @click="moveList.pop()">Undo</div> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      swap: false,
      player1nname: "",
      player2name: "",
      board: {
        width: 9,
        height: 9,
        tiles: [],
      },
      moveList: [],
      newMove: {
        Player: 1,
        Type: "EARTH",
        X: 0,
        Y: 0,
      },
      whosMove: 1,
      infection: false,
    };
  },
  computed: {
    p1score: function () {
      let count1 = 0;
      let count2 = 0;

      for (let x = 0; x <= this.board.width; x++) {
        for (let y = 0; y <= this.board.height; y++) {
          let checkTile = this.ocupiedTile([x, y]);
          if (checkTile && checkTile.Player == 1) count1++;
          if (checkTile && checkTile.Player == 2) count2++;
        }
      }

      return [count1, count2];
    },
    currentDistribution: function () {
      let countE = 0;
      let countW = 0;
      let countF = 0;

      for (let x = 0; x <= this.board.width; x++) {
        for (let y = 0; y <= this.board.height; y++) {
          let checkTile = this.ocupiedTile([x, y]);
          if (checkTile && checkTile.Type == "EARTH") countE++;
          if (checkTile && checkTile.Type == "WATER") countW++;
          if (checkTile && checkTile.Type == "FIRE") countF++;
        }
      }

      return [countE, countW, countF];
    },

    currentDistribution1: function () {
      let countE = 0;
      let countW = 0;
      let countF = 0;

      for (let x = 0; x <= this.board.width; x++) {
        for (let y = 0; y <= this.board.height; y++) {
          let checkTile = this.ocupiedTile([x, y]);
          if (checkTile && checkTile.Player == 1 && checkTile.Type == "EARTH")
            countE++;
          if (checkTile && checkTile.Player == 1 && checkTile.Type == "WATER")
            countW++;
          if (checkTile && checkTile.Player == 1 && checkTile.Type == "FIRE")
            countF++;
        }
      }
      return [countE, countW, countF];
    },
    currentDistribution2: function () {
      let countE = 0;
      let countW = 0;
      let countF = 0;

      for (let x = 0; x <= this.board.width; x++) {
        for (let y = 0; y <= this.board.height; y++) {
          let checkTile = this.ocupiedTile([x, y]);
          if (checkTile && checkTile.Player == 2 && checkTile.Type == "EARTH")
            countE++;
          if (checkTile && checkTile.Player == 2 && checkTile.Type == "WATER")
            countW++;
          if (checkTile && checkTile.Player == 2 && checkTile.Type == "FIRE")
            countF++;
        }
      }
      return [countE, countW, countF];
    },
  },
  created() {
    if (this.$route.query.width)
      this.board.width = parseInt(this.$route.query.width);
    if (this.$route.query.height)
      this.board.height = parseInt(this.$route.query.height);

    this.player1name = this.$route.query.player1;
    this.player2name = this.$route.query.player2;
  },
  methods: {
    selectTile: function (x, y) {
      console.log("============================================");
      console.log("PLACED TILE @ [" + x + "," + y + "]");

      if (!this.selectableTile(x, y) || this.ocupiedTile([x, y])) return false;

      this.newMove.Player = this.whosMove;
      this.newMove.X = x;
      this.newMove.Y = y;
      this.moveList.push(Object.assign({}, this.newMove));

      // HOLD ORIGINAL TILE
      let origTile = [x, y];

      let changeTiles = [];

      // CHECK DIRECTION (1-8)
      let xRule = "";
      let yRule = "";
      let direction = 0;

      for (direction = 0; direction < 8; direction++) {
        let bufferedTiles = [];

        console.log(
          "DIRECTION",
          ["N", "NE", "E", "SE", "S", "SW", "W", "NW"][direction]
        );

        x = origTile[0];
        y = origTile[1];

        // SET X RULE - SET Y RULE

        // n
        if (direction == 0) {
          xRule = "x = x";
          yRule = "y = y-1";
        }
        // ne
        if (direction == 1) {
          xRule = "x = x+1";
          yRule = "y = y-1";
        }
        // e
        if (direction == 2) {
          xRule = "x = x+1";
          yRule = "y = y";
        }
        // se
        if (direction == 3) {
          xRule = "x = x+1";
          yRule = "y = y+1";
        }
        // s
        if (direction == 4) {
          xRule = "x = x";
          yRule = "y = y+1";
        }
        // sw
        if (direction == 5) {
          xRule = "x = x-1";
          yRule = "y = y+1";
        }
        // w
        if (direction == 6) {
          xRule = "x = x-1";
          yRule = "y = y";
        }
        // nw
        if (direction == 7) {
          xRule = "x = x-1";
          yRule = "y = y-1";
        }

        let checkingDirection = true;

        do {
          let foundJoiningTile = false;
          // SET CheckXY
          eval(xRule);
          eval(yRule);

          // CHECK OFF BOARD DO NOTHING
          if (x < 0 || y < 0 || x > this.board.width || y > this.board.height) {
            if (foundJoiningTile) {
              changeTiles = [...bufferedTiles];
            }
            checkingDirection = false;
          }

          console.log("CHECKING TILE @ [" + x + "," + y + "]");
          let checkTile = this.ocupiedTile([x, y]);
          if (checkTile && checkTile.Player != this.whosMove) {
            // DO CHECK IF ENEMY TILE ADJACENT
            console.log("FOUND TILE @ [" + x + "," + y + "]", checkTile);

            if (
              (this.newMove.Type == "EARTH" && checkTile.Type == "WATER") ||
              (this.newMove.Type == "EARTH" && checkTile.Type == "EARTH") ||
              (this.newMove.Type == "WATER" && checkTile.Type == "FIRE") ||
              (this.newMove.Type == "WATER" && checkTile.Type == "WATER") ||
              (this.newMove.Type == "FIRE" && checkTile.Type == "EARTH") ||
              (this.newMove.Type == "FIRE" && checkTile.Type == "FIRE")
            ) {
              console.log("PLACED TILE BEATS TILE @ [" + x + "," + y + "]");

              bufferedTiles.push({ X: x, Y: y });

              if (this.infection) {
                bufferedTiles.forEach((tile) => {
                  this.moveList.push({
                    Player: this.newMove.Player,
                    Type: this.newMove.Type,
                    X: tile.X,
                    Y: tile.Y,
                  });
                });
                checkingDirection = false;
              }
            } else {
              checkingDirection = false;
            }
          } else if (checkTile && checkTile.Player == this.whosMove) {
            console.log("FOUND OWN TILE @ [" + x + "," + y + "]", checkTile);
            if (checkTile.Type == this.newMove.Type) {
              bufferedTiles.forEach((tile) => {
                this.moveList.push({
                  Player: this.newMove.Player,
                  Type: this.newMove.Type,
                  X: tile.X,
                  Y: tile.Y,
                });
              });

              checkingDirection = false;
            } else {
              // CLEAR BUFFER
              checkingDirection = false;
            }
          } else {
            checkingDirection = false;
          }
        } while (checkingDirection);
      }

      this.whosMove = this.whosMove == 1 ? 2 : 1;
    },
    compareTile: function () {},
    tileClass: function (x, y) {
      let lastMove = this.moveList
        .slice()
        .reverse()
        .find((move) => move.X == x && move.Y == y);

      return lastMove ? "player" + lastMove.Player + " " + lastMove.Type : "";
    },
    selectableTile: function (x, y) {
      if (this.moveList.length == 0) return "available";

      let tile_n = [x, y - 1];
      let tile_n_e = [x + 1, y - 1];
      let tile_e = [x + 1, y];
      let tile_s_e = [x + 1, y + 1];
      let tile_s = [x, y + 1];
      let tile_s_w = [x - 1, y + 1];
      let tile_w = [x - 1, y];
      let tile_n_w = [x - 1, y - 1];

      return !this.ocupiedTile(tile_n) &&
        !this.ocupiedTile(tile_n_e) &&
        !this.ocupiedTile(tile_e) &&
        !this.ocupiedTile(tile_s_e) &&
        !this.ocupiedTile(tile_s) &&
        !this.ocupiedTile(tile_s_w) &&
        !this.ocupiedTile(tile_w) &&
        !this.ocupiedTile(tile_n_w)
        ? ""
        : "available";
    },
    ocupiedTile: function (coords = [0, 0]) {
      return this.moveList
        .slice()
        .reverse()
        .find((move) => move.X == coords[0] && move.Y == coords[1]);
    },
  },
};
</script>

<style scoped lang="less">
@import url("../assets/css/board2.less");
</style>