<template>
  <div class="home">
    <h1>Elements</h1>

    <div class="row" style="display: none">
      <div class="col-sm-4">
        <router-link :to="'/login'" class="button">Login</router-link>
      </div>
      <div class="col-sm-4">
        <router-link :to="'/register'" class="button">Register</router-link>
      </div>
      <div class="col-sm-4">
        <router-link :to="'/match'" class="button">Match</router-link>
      </div>
    </div>

    <form @submit.prevent="newMatch()">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="label">Player 1</div>
              <input
                type="text"
                name="player1"
                class="form-control"
                v-model.number="gridSize.player1"
                maxlength="10"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <div class="label">Player 2</div>
              <input
                type="text"
                name="player2"
                class="form-control"
                v-model.number="gridSize.player2"
                maxlength="10"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="label">Width</div>
              <input
                type="number"
                name="width"
                class="form-control"
                v-model.number="gridSize.width"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <div class="label">Height</div>
              <input
                type="number"
                name="height"
                class="form-control"
                v-model.number="gridSize.height"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button type="submit">GO!</button>
          </div>
        </div>
      </div>
    </form>

    <div class="gridwrapper" style="display: none">
      <div
        class="grid"
        :style="'grid-template-rows: repeat(' + gridSize.height + ', 1fr);'"
      >
        <div
          v-for="y in gridSize.height"
          :key="y"
          :style="
            'grid-template-columns: repeat(' + gridSize.width + ', 1fr); '
          "
        >
          <div v-for="x in gridSize.width" :key="x">
            {{ x + "," + y }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      gridSize: {
        player1: "Player 1",
        player2: "Player 2",
        width: 7,
        height: 7,
      },
    };
  },
  methods: {
    newMatch: function () {
      this.$router.push({
        path: "/match",
        query: {
          player1: this.gridSize.player1,
          player2: this.gridSize.player2,
          width: this.gridSize.width,
          height: this.gridSize.height,
          type: "standard",
        },
      });
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.gridwrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 500px;
  // padding-top: 100%;
  transform: translate(-50%, -50%);

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: grid;

    > div {
      display: grid;
      > div {
        border: solid 1px #cecece;
        font-size: 30%;
      }
    }
  }
}
</style>
